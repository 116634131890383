$orange1: #f9b872;
$darkcard: #151515;
.banner {
  position: relative;
  margin-top: 0;
}
.banner::before {
  content: "";
  background-image: url("../../assets/img/project.jpg");
  background-size: cover;
  position: absolute;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  opacity: 0.7;
}
.welcome-div {
  display: flex;
  justify-content: center;
}
.banner .tagline {
  position: relative;
  font-weight: 700;
  letter-spacing: 0.8px;
  padding: 8px 10px;
  background: linear-gradient(90.21deg, $darkcard -5.91%, $orange1 111.58%);
  border: 1px solid rgba(255, 255, 255, 0.5);
  font-size: 20px;
  margin-bottom: 16px;
  display: inline-block;
}
.banner-text {
  position: relative;
  animation: myimgLeft 2s ease-out 1;
}

h1 {
  position: relative;
  font-size: 40px;
  font-weight: 600;

  line-height: 1;
  margin-bottom: 10px;
  display: block;
  text-align: center;
}
.banner .wrap {
  text-align: center;
  min-height: 40px;
}
.banner p {
  position: relative;
  color: #b8b8b8;
  font-size: 18px;
  line-height: 1.5em;
  width: 100%;
  text-align: justify;
}
.orange-btn {
  position: relative;
  font-weight: 700;
  letter-spacing: 0.8px;
  padding: 8px 10px;
  color: #fff;
  border: 1px solid $orange1;
  position: relative;
  background-color: transparent;
  transition: 0.7s ease-in-out;
  display: flex;
  align-items: center;
  border-color: $orange1;
}
.left-btn {
  margin-left: 18px;
}
.left-btn svg {
  margin-left: 10px;
  transition: 0.7s ease-in-out;
}

.orange-btn svg {
  position: relative;
  font-size: 25px;

  line-height: 1;
}
.left-btn:hover svg {
  position: relative;
  margin-left: 50px;
}
.left-btn:hover {
  position: relative;
  background: linear-gradient(90.21deg, $orange1 -5.91%, $darkcard 111.58%);
}

.wrap {
  color: $orange1;
}

.link-decor {
  text-decoration: none;
  color: #fff !important;
}
.banner-img {
  position: relative;
  opacity: 0.8;
  animation: updown 4s 3.5s linear infinite, myimgTop 2s ease-out 1;
  width: 45vh;
}

.banner-col {
  display: flex;
  justify-content: center;
}
