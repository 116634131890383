$orange1: #f9b872;
$darkcard: #151515;

.contact h2 {
  font-size: 45px;
  font-weight: 700;
  text-align: center;
}
.form-details {
  background-color: $darkcard;
  color: white;
  border-bottom: 2px solid $orange1;
}
.form-details::placeholder {
  color: white;
  opacity: 0.2;
}
.contact-logo {
  position: relative;
  animation: updown 4s 3.5s linear infinite, myimgLeft 2s ease-out 1;
}
.logo-col {
  display: flex;
  justify-content: center;
}
input {
  border: none;
}
textarea {
  border: 2px solid $orange1;
  background-color: $darkcard;
  color: white;
}
textarea::placeholder {
  color: white;
  opacity: 0.2;
  text-align: center;
  padding: 10px 0 10px 0;
}

.form-btn {
  background-color: $darkcard;
}
button {
  border: 2px solid $orange1;
}
.button-span {
  padding: 15px;
  opacity: 0.8;
}
textarea,
input:focus {
  transition: 0.2s ease-in-out;
  outline: none;
}
.form-side {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  animation: myimgRight 2s ease-out 1;
}
