$orange1: #f9b872;
$darkcard: #151515;
@font-face {
  font-family: Centra;
  src: url("./assets/fonts/CentraNo2-Bold.ttf");
  font-weight: 700;
}
@font-face {
  font-family: Centra;
  src: url("./assets/fonts/CentraNo2-Medium.ttf");
  font-weight: 500;
}
@font-face {
  font-family: Centra;
  src: url("./assets/fonts/CentraNo2-Book.ttf");
  font-weight: 400;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}
body {
  font-weight: 400;
  /* overflow: hidden; */
  position: relative;
  background-color: #121212 !important;
  color: #fff !important;
  font-family: "Centra", sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  line-height: normal;
}
.side-bx {
  background: $darkcard;
  border-radius: 64px;
  text-align: center;
  padding: 60px 50px;
  margin-top: -60px;
  animation: myimgBottom 2s ease-out 1;
  position: relative;
}

// .seprator {
// background-color:$orange1;
//   height: 1px;

// padding:0px 50px;

// }
@media only screen and (max-width: 600px) {
}
@media only screen and (max-width: 768px) {
  .carousel-caption p {
    font-size: 8px;
  }
  .carousel {
    width: 100%;
    height: auto;
  }
  .card-item {
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .proj-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .section-card {
    padding: 150px 0 150px 0;
  }
  .section-card-revers {
    padding: 150px 0 150px 0;
  }
  .slide-logo,
  .banner-size,
  .responsive {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .banner {
    padding: 300px 0 260px 0;
  }
  img.side-logo {
    height: 15vh;
    width: auto;
    margin: 30px;
  }
  .soft-img {
    flex-wrap: wrap;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
  }
}

@media only screen and (min-width: 768px) {
  .card-item {
    flex-wrap: wrap;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .carousel-caption p {
    font-size: 15px;
  }
  .carousel {
    width: 80%;
    height: auto;
  }
  .section-card {
    padding: 250px 0 250px 0;
  }
  .section-card-revers {
    padding: 250px 0 250px 0;
  }
  .space-elem {
    margin: 5px;
  }
  .banner {
    padding: 260px 0 260px 0;
  }
  .soft-img {
    flex-wrap: wrap;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .btn-gallery {
    font-size: 0.5rem;
  }

  .side-bx {
    position: relative;
  }
  img.side-logo {
    height: 20vh;
    width: auto;
  }
  // .card-project {
  //   width: 50%;
  //   height: auto;
  // }
}
@media only screen and (max-width: 992px) {
  .descr-proj {
    border: 1px solid $orange1;
    margin-bottom: 5px;
  }
  .descr-row {
    flex-direction: column;
    text-align: center;
  }
  .descr-row svg {
    display: none;
  }
}
@media only screen and (min-width: 1200px) {
  .carousel {
    width: 70%;
    height: auto;
  }
}

.section-card {
  position: relative;
  background-image: url("./assets/img/skills.jpg");
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-content: center;
}
.section-card-revers {
  position: relative;
  margin-top: 0;
  background-image: url("./assets/img/project.jpg");
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
}
@keyframes updown {
  0% {
    transform: translateY(-20px);
  }
  50% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(-20px);
  }
}
@keyframes myimgTop {
  from {
    top: -200px;
  }
  to {
    top: 0;
  }
}
@keyframes myimgLeft {
  from {
    left: -200px;
  }
  to {
    left: 0;
  }
}
@keyframes myimgRight {
  from {
    right: -200px;
  }
  to {
    right: 0;
  }
}
@keyframes myimgBottom {
  from {
    bottom: -200px;
  }
  to {
    bottom: 0;
  }
}
@keyframes myBtn {
  from {
    right: -25px;
    color: $darkcard;
  }
  to {
    right: 25px;
    color: #f9b872;
  }
}
@keyframes myBtnVisit {
  from {
    right: 25px;
    color: $darkcard;
  }
  to {
    right: -25px;
    color: #f9b872;
  }
}
