$orange1: #f9b872;
$darkcard: #151515;
.container-proj {
  position: relative;
  width: 100%;
}
.logo-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
}
.proj-container {
  position: relative;
  animation: myimgRight 2s ease-out 1;
  color: #151515;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.projects h2 {
  font-size: 45px;
  font-weight: 700;
  text-align: center;
}

.project-logo {
  position: relative;
  animation: updown 4s 3.5s linear infinite, myimgLeft 2s ease-out 1;
}
.card-body {
  margin: 0;
  padding: 0 0;
  background-color: $darkcard;
}
.title-card > a {
  text-decoration: none;
}
.card-project {
  margin: 1em;
  border: 0;
}

.middle {
  position: absolute;
  bottom: 0;
  background: $orange1;
  background: rgba(0, 0, 0, 0.5);
  color: #f1f1f1;
  width: 100%;
  transition: 0.5s ease;
  opacity: 0;
  color: white;
  font-size: 20px;
  padding: 5px;
  text-align: center;
}

.card-body:hover .middle {
  opacity: 1;
}

.btn-card {
  font-size: 0.5rem;
}

.card-img-top {
  transition: 0.5s ease;
}
a {
  text-decoration-style: none;
}
.btn-gallery {
  background-color: black;
  color: $orange1;
  border: 1px solid $orange1;
  font-size: small;
}

.btn-gallery:hover {
  background-color: $orange1;
  color: black;
  border: 1px solid black;
}

.title-card {
  height: 100%;
  margin-top: 0;
  background: $darkcard;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-top: 1px solid #f9b872;
  box-shadow: 0px 0px 10px 0px $orange1;
  color: white;
}

/// //Project page
///
///

.proj-title {
  margin: 3rem;
}
.projects-elem {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  margin: 0 5vw;
}
.proj-description {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.about-container {
  margin: 2rem;
}

.projects-elem .navbar {
  display: none;
}
a {
  text-decoration: none;
}
.carousel-caption p {
  background-color: rgba(206, 136, 14, 0.608);
  box-shadow: 0px 0px 10px 0px $darkcard;
  color: $darkcard;
  font-weight: bolder;
}
.back-btn-top,
.visit-btn-top {
  position: relative;
}
.back-btn-top .orange-btn {
  background-color: transparent;
  animation: myBtn 3s linear infinite;
  color: $orange1;
  border: none;
}
.back-btn-top .orange-btn svg {
  margin: 0 25px;
  animation: myBtn 3s linear infinite;
}
.visit-btn-top .orange-btn {
  background-color: transparent;
  animation: myBtnVisit 3s linear infinite;
  color: $orange1;
  border: none;
}
.visit-btn-top .orange-btn svg {
  margin: 0 25px;
  animation: myBtnVisit 3s linear infinite;
}

.carousel {
  width: 90%;
}
.carousel-item,
.carousel-div {
  display: none;
  width: 100%;
}
.descr-row {
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}
.descr-proj {
  font-size: larger;
  font-weight: bolder;
}
.about-proj {
  font-size: large;
}

.tech-img ul {
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  padding-left: 0;
}
.tech-img img {
  height: 3em;
}
.tech-img ul li {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.carousel-item img {
  max-width: 100%;
  height: auto;
}

.carousel .carousel-indicators button {
  background-color: $orange1;
  border: 1px solid $orange1;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  box-shadow: 0px 0px 10px 0px $darkcard;
}

.left-btn {
  display: contents;
  position: relative;
  font-weight: 700;
  letter-spacing: 0.8px;
  padding: 8px 10px;
  color: #fff;
  border: 1px solid $orange1;
  position: relative;
  background-color: transparent;
  transition: 0.7s ease-in-out;
  display: flex;
  align-items: center;
  border-color: $orange1;
}

.right-btn svg {
  margin-left: 18px;

  transition: 0.7s ease-in-out;
}

.right-btn:hover svg {
  margin-left: 0px;
  margin-right: 15px;
  position: relative;
}
.right-btn:hover {
  font-weight: 700;
  letter-spacing: 0.8px;
  color: #fff;
  border: 1px solid $orange1;
  position: relative;
  background-color: transparent;
  transition: 0.7s ease-in-out;

  align-items: center;
  border-color: $orange1;
  background: linear-gradient(90.21deg, $darkcard -5.91%, $orange1 111.58%);
}
.right-btn span {
  text-decoration: none;
}

//carousel next/previous button

.carousel-control-next-icon,
.carousel-control-prev-icon {
  background-color: $darkcard;
  border-radius: 50%;
  box-shadow: 0px 0px 10px 0px $orange1;
  animation: pulse 2s infinite;
}
.carousel-control-next-icon:hover,
.carousel-control-prev-icon:hover {
  animation: none;
}

@keyframes pulse {
  0% {
    transform: transform(scale(0.9));
    opacity: 0.7;
  }
  70% {
    transform: transform(scale(1));
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0.577);

    opacity: 1;
  }
  100% {
    transform: transform(scale(0.9));
    box-shadow: 0 0 0 0 $orange1;

    opacity: 1;
  }
}
