$orange1: #f9b872;
$darkcard: #151515;
.footer {
  box-shadow: 0px 0px 10px 0px $orange1;
  position: relative;
  margin-top: 0;
  padding: 30px 0 30px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer img {
  width: min-content;
  height: 5rem;
  padding-top: 0;
}
.social-icon img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 50%;
}
.footer-col {
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer-navlink {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;
}
.footer-navlink a {
  font-weight: 400;
  color: #fff !important;
  letter-spacing: 0.8px;
  padding: 15px 25px;
  font-size: 18px;
  opacity: 0.75;
  text-decoration: none;
}
.footer-navlink a:hover {
  opacity: 1;
}
p {
  margin-bottom: 0;
}
.footer-text {
  opacity: 0.75;
}
