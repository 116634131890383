$orange1: #f9b872;
$darkcard: #151515;

.skills h2 {
  font-size: 45px;
  font-weight: 700;
  text-align: center;
}
.skills .item h5 {
  font-size: 75%;
  transition: 0.7s;
  color: rgba(255, 255, 255, 0.21);
}
.skills .item:hover h5 {
  font-size: 120%;
  color: white;
}
.skill-logo {
  position: relative;
  animation: updown 4s 3.5s linear infinite, myimgLeft 2s ease-out 1;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
}
.side-logo {
  height: 20em;
  width: auto;
}

.skill-items {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  flex-wrap: wrap;
  justify-content: space-between;
}

.skill-item {
  display: flex;
  flex-direction: column;
}
.skill-name {
  color: white;
}

.soft-img img {
  width: 35%;
  height: auto;
}

.skill-tech {
  width: 25%;
  height: auto;
}
.skill-item img {
  overflow: visible;
  transition: 0.7s;
  display: flex;
  flex-direction: row;
}
.soft-skills ul {
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: larger;
}
.skills-parts {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 0;
}
.all-skills {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid $orange1;
}

.soft-skills,
.skills-parts {
  position: relative;
  animation: myimgRight 2s ease-out 1;
}
