$orange1: #f9b872;
$darkcard: #151515;
.container {
  width: 100%;
}
nav.navbar {
  padding: 8px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition: 0.4s ease-in-out;
}

nav.navbar.scrolled {
  padding: 0px 0;
  background-color: #121212;
  box-shadow: 0px 0px 10px 0px $orange1;
}

nav.navbar a.navbar-brand img {
  width: auto;
  height: 5rem;
}

nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 400;
  color: #fff !important;
  letter-spacing: 0.8px;
  padding: 0 25px;
  font-size: 18px;
  opacity: 0.75;
}

nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
  opacity: 1;
}
span.navbar-text {
  display: flex;
  align-items: center;
}

.social-icon {
  display: inline-block;
  margin-left: 14px;
}
.social-icon a {
  width:35px;
  height:35px;
  background: rgba(217, 217, 217, 0.396);
  display: inline-flex;
  border-radius: 50%;
  margin-right: 6px;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border: 1px solid #f9b872;
}

.social-icon a::before {
  content: "";
  width: 45px;
  height: 45px;
  position: absolute;
  border: 1px solid black;
  border-radius: 50%;
  transform: scale(0);
  transition: 0.6s ease;
}
#linkedln::before {
  background: #0072b1;
}
#facebook::before {
  background: #3b5998;
}
#github::before {
  background: rgba(255, 255, 255, 0.48);

  // background: radial-gradient(
  //     circle farthest-corner at 35% 90%,
  //     #fec564,
  //     transparent 50%
  //   ),
  //   radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%),
  //   radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%),
  //   radial-gradient(
  //     ellipse farthest-corner at 20% -50%,
  //     #5258cf,
  //     transparent 50%
  //   ),
  //   radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%),
  //   radial-gradient(
  //     ellipse farthest-corner at 60% -20%,
  //     #893dc2,
  //     transparent 50%
  //   ),
  //   radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent),
  //   linear-gradient(
  //     #6559ca,
  //     #bc318f 30%,
  //     #e33f5f 50%,
  //     #f77638 70%,
  //     #fec66d 100%
  //   );
}

.social-icon a:hover::before {
  transform: scale(1);
}
.social-icon a img {
  width: 80%;
  height: auto;
  z-index: 1;
  transition: 0.3s ease-in-out;
}
.social-icon a:hover img {
  filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%)
    hue-rotate(346deg) brightness(95%) contrast(86%);
}

.connect-button {
  font-weight: 700;
  color: #fff;
  border: 1px solid #f9b872;
  padding: 8px 16px;

  position: relative;
  background-color: transparent;
  transition: 0.3s ease-in-out;
}
.nav-connect {
  font-size: 13px;
  margin-left: 18px;
}
.connect-button span {
  z-index: 1;
}
.connect-button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  /* background-color: #fff; */
  background: linear-gradient(
    90.21deg,
    rgba(249, 184, 114, 1) -5.91%,
    rgba(0, 0, 0, 0.5) 111.58%
  );
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.5s ease-in-out;
}
.connect-button:hover {
  color: #f9b872;
}
.connect-button:hover::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  color: #f9b872;
}
nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}
nav.navbar .navbar-toggler {
  color: #fff;
  transition: 0.5s ease-in-out;
}
nav.navbar .navbar-toggler:hover {
  color: $orange1;
  border: 1px solid $orange1;
}
nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid #f9b872;
  transition: all 300ms linear;
  top: -2px;
}

nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
  width: 24px;
  position: absolute;
  height: 2px;
  background-color: $orange1;
  top: 0;
  left: 0;
  content: "";
  z-index: 2;
  transition: all 300ms linear;
}
nav.navbar .navbar-toggler-icon:after {
  top: 8px;
}
